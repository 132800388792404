import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    const [TextWithRatesRenewalHidden, setTextWithRatesRenewalHidden] = useLocalStorage("TextWithRatesRenewalHidden", "");
    const navigate = useNavigate();
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
             <input type="hidden" value={TextWithRatesRenewalHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/WouldYouLikeGet">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Te gustaría recibir un texto con las tarifas de renovación a este número del que llama?</h2>
                </Grid>
                <Grid item xs={12}>                
                    <Button onClick={ () => { setTextWithRatesRenewalHidden("Yes"); setTimeout(() => { navigate("/dmv/placas/ElectronicReceiptShippingUpdates")}, 100) }} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Si</Button>
                </Grid>
                <Grid item xs={12}>                   
                    <Button onClick={ () => { setTextWithRatesRenewalHidden("No"); setTimeout(() => { navigate("/dmv/placas/ElectronicReceiptShippingUpdates")}, 100) }} variant="contained" color="error" size="large" sx={{ width: "50%" }}>No</Button>
                </Grid>

                <Outlet />
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;