import RightClickMenu from "../components/rightClickMenu";
import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

const AppBoardScreen = () => {

    const [IsVehicleRegisteredStateCaliforniaHidden, setIsVehicleRegisteredStateCaliforniaHidden] = useLocalStorage("IsVehicleRegisteredStateCaliforniaHidden", "");
    const navigate = useNavigate();

    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <input type="hidden" value={IsVehicleRegisteredStateCaliforniaHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/RegistrationRequireInfo">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">﻿¿Tienes la placa delantera y trasera?</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setIsVehicleRegisteredStateCaliforniaHidden("Yes"); setTimeout(() => { navigate("/dmv/cambio/WhatZipCodeCarRegistered")}, 100) }} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Si</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setIsVehicleRegisteredStateCaliforniaHidden("No"); setTimeout(() => { navigate("/dmv/cambio/wouldLikeGetReplacement$23")}, 100) }} variant="contained" color="error" size="large" sx={{ width: "50%" }}>No</Button>
                </Grid>
                
                <RightClickMenu/>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;