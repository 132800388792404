import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";
import { useState } from "react";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {
    const [EligeTuEnvío, setEligeTuEnvío] = useLocalStorage("EligeTuEnvío", "");
    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (EligeTuEnvío.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();

    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/ShippingAddress">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Total a Pagar</h2>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">$19.99</h2>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Expirado tu envio</h2>
                </Grid>
                <Grid item xs={12}>
                    <TextField  onKeyUp={inputChangeHandler} onChange={(e) => setEligeTuEnvío(e.target.value)} value= {EligeTuEnvío} label="Elige tu envío" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/TakeThePayment">
                        <Button disabled={!buttonStatus}  variant="contained" color="success" size="large" sx={{ width: "50%" }}>Continuar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h5 className="subHeading">Cancelar</h5>
                </Grid>

                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;