import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useState } from "react";
import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";
const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {

    const [EligeTuEnvío, setEligeTuEnvío] = useLocalStorage("EligeTuEnvío", "");

    const [ThisYourVehicleHidden, setThisYourVehicleHidden] = useLocalStorage("ThisYourVehicleHidden", "");
    const [buttonStatus, setButtonStatus] = useState(false);
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (ThisYourVehicleHidden.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();

    const navigate = useNavigate();

    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
             <input type="hidden" value={ThisYourVehicleHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/LastDigitsofVin">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Este es tu vehículo?</h2>
                </Grid>
                <Grid item xs={12}>
                    <TextField  onKeyUp={inputChangeHandler} onChange={(e) => setEligeTuEnvío(e.target.value)} value={EligeTuEnvío} label="2015 Chevrolet Silverado 1FED650033265FGS0" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!buttonStatus} onClick={ () => { setThisYourVehicleHidden("Yes"); setTimeout(() => { navigate("/dmv/cambio/Milesvehiclehave")}, 100) }} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Si</Button>              
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={!buttonStatus} onClick={ () => { setThisYourVehicleHidden("No"); setTimeout(() => { navigate("/dmv/cambio/WouldYouLikeGete")}, 100) }} variant="contained" color="error" size="large" sx={{ width: "50%" }}>No</Button>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;