import { useState } from "react";
import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {

    const [ZipCode, setZipCode] = useLocalStorage("ZipCode", "");

    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if ( ZipCode.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();

    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/IsVehicleRegisteredStateCalifornia">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Cuentas con el título del Estado de California?</h2>
                </Grid>
               <Grid item xs={12}>
                    <TextField onKeyUp={inputChangeHandler} onChange={(e) => setZipCode(e.target.value)} value={ZipCode}  label="Zip Code" variant="outlined" sx={TextFieldStyle} />
                </Grid>
            
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/WhatTypeVehicle">
                        <Button disabled={!buttonStatus} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>si</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/WhatYearIstheVehicle?">
                        <Button disabled={!buttonStatus} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>No</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/ItImportanTheChangeStatus">
                        <Button disabled={!buttonStatus} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>Cuento con título pero es de otro estado</Button>
                    </Link>
                </Grid>
                <Outlet/>
            </Grid>
            <Grid item xs={12}>
                <Link to="/dmv/cambio/IUnderstandButWorry">
                    <Button disabled={!buttonStatus} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>Soy de otro estado y no cuento con título</Button>
                </Link>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;