import useContextMenu from "./useContextMenu";

const Menu = () => {
  const { anchorPoint, show } = useContextMenu();

  if (show) {
    return (
      <ul className="menu" style={{ top: anchorPoint.y, left: anchorPoint.x }}>
        <li>¿Dónde puedo encontrar esa información en la registración?</li>
        <hr />
        <li><a href="https://drive.google.com/file/d/1WG10mPM5QgxR8ERgJfY9WfX9Y1IU5BFB/view" target="_blank" rel="noreferrer">Tipos de vehiculos</a></li>
        <hr />
        <li><a href="https://drive.google.com/file/d/1aYDDcV4ZUKmBtd5RpFihmXurspxemcSt/view" target="_blank" rel="noreferrer">Ejemplo de aviso de renovación</a></li>
        <hr />
        <li><a href="https://drive.google.com/file/d/1xI4sLU7uU5MPgWSdTS3KYNieUIKF2fGT/view" target="_blank" rel="noreferrer">Ejemplo de registración</a></li>
        <hr />
        <li><a href="https://drive.google.com/file/d/1KTtSNg-vS9wWrZOxdIYZu9YR_vBf6-fx/view" target="_blank" rel="noreferrer">Vehiculos antes de 1981 tienen un VIN de menos de 17 digitos</a></li>
      </ul>
    );
  }
  return <></>;
};

export default Menu;