import RightClickMenu from "../components/rightClickMenu";

import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useNavigate, Outlet, Link } from "react-router-dom";

import carPNG from '../img/car.png';

const AppBoardScreen = () => {

    const [ProvideWithDocumentsHidden, setProvideWithDocumentsHidden] = useLocalStorage("ProvideWithDocumentsHidden", "");

    const navigate = useNavigate();

    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <input type="hidden" value={ProvideWithDocumentsHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WhatZipCodeCarRegistered">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Podemos proporcionarte los documentos que necesitas por $19.99, solo es necesario que los devuelvas firmados</h2>
                </Grid>
                <Grid alignItems="center" item container spacing={2} sx={12}>
                    <Grid item xs={2}>
                        <img src={carPNG} alt="Car" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setProvideWithDocumentsHidden("Yes"); setTimeout(() => { navigate("/dmv/cambio/ShippingAddress")}, 100) }} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>SI, Ordenar</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setProvideWithDocumentsHidden("No"); setTimeout(() => { navigate("/dmv/cambio/SharingImageInformation")}, 100) }} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>No</Button>
                </Grid>

                <RightClickMenu/>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen ;