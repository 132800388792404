import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    const [CurrentlyYourProcessRequirePerformingSmogHidden, setCurrentlyYourProcessRequirePerformingSmogHidden] = useLocalStorage("CurrentlyYourProcessRequirePerformingSmog", "");
    const navigate = useNavigate();
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
        <input type="hidden" value={CurrentlyYourProcessRequirePerformingSmogHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/EducateCustomerThatCompleteNecessarySince">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Actualmente, su proceso requiere realizar el SMOG para poder continuar</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setCurrentlyYourProcessRequirePerformingSmogHidden("Yes"); setTimeout(() => { navigate("/dmv/cambio/HaveYouDoneTheSmogYet")}, 100) }} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Continuar</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setCurrentlyYourProcessRequirePerformingSmogHidden("No"); setTimeout(() => { navigate("/dmv/cambio/YourVehicleHasReportStolenSituation")}, 100) } } variant="contained" color="success" size="large" sx={{ width: "50%" }}>Test</Button>
                </Grid>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;