import RightClickMenu from "../components/rightClickMenu";

import { useState } from "react";
import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import InputLabel from '@mui/material/InputLabel';
import Select  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

import carPNG from '../img/car.png';

const AppBoardScreen = () => {

    const [VehicleType, setVehicleType] = useLocalStorage("VehicleType", "Auto");
    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (VehicleType.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();
    
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WhatZipCodeCarRegistered">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Qué tipo de vehículo es?</h2>
                </Grid>
                <Grid alignItems="center" item container spacing={2} sx={12}>
                    <Grid item xs={2}>
                        <img src={carPNG} alt="Car" />
                    </Grid>
                    <Grid item xs={10}>
                        <InputLabel id="VehicleTypeAuto">Auto</InputLabel>
                        <Select
                            labelId="VehicleTypeAuto"
                            value={VehicleType}
                            label="Auto"
                            onChange={(e) => setVehicleType(e.target.value)}
                        >
                            <MenuItem value="Auto">Auto</MenuItem>
                            <MenuItem value="Commercial">Pick-up</MenuItem>
                            <MenuItem value="Auto Van">Van</MenuItem>
                            <MenuItem value="No API">Big Truck 10k LB + (Tractor)</MenuItem>
                            <MenuItem value="Motorcycle Utility Trailer Travel Trailer RV">Tractor-Trailer Motorcycle Off Road Motorcycle SXS (RZR, Canam)</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/HaveLicensePlateNumber">
                        <Button disabled={!buttonStatus}  className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>Continuar</Button>
                    </Link>
                </Grid>

                <RightClickMenu />
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen ;