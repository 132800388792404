import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

const screen2 = () => {
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WeWereUnableProcessSecenod">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Renovación completada y envío generado</h2>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/ItLooksLikeAlreadySomeOtherProcesStarted">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Finalizar</Button>
                    </Link>
                </Grid>
                
                <Outlet />
            </Grid>
        </Box>
    );
};

export default screen2;