import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {
    const [NumeroDeTarjetanName, setNumeroDeTarjetanName] = useLocalStorage("NumeroDeTarjetanName", "");
    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (NumeroDeTarjetanName.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();
    
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/YourDmvFees">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Agregar una Tarjeta</h2>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">informacion de la tarjeta </h2>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Informacion de la tarjeta</h2>
                </Grid>
                <Grid item xs={12}>
                    <TextField onKeyUp={inputChangeHandler} onChange={(e) => setNumeroDeTarjetanName(e.target.value)} value= {NumeroDeTarjetanName} label="Numero de tarjeta" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Pais o region</h2>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/RenovationCompletedShipmentGenerated">
                        <Button disabled={!buttonStatus} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Procesar pago</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/WithNameChangeProcess">
                        <Button disabled={!buttonStatus} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Cancelar</Button>
                    </Link>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;