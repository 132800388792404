import RightClickMenu from "../components/rightClickMenu";
import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";
import { useState } from "react";

import WriteLicensePlateNumberPNG from'../img/WriteLicensePlateNumber.png';

const TextFieldStyle = { width: '100%' };

const AppBoardScreen  = () => {
    const [ReporteDeVenta, setReporteDeVenta] = useLocalStorage("ReporteDeVenta", "");
    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (ReporteDeVenta.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();

    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/HaveLicensePlateNumber">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Escribe sl número de placa</h2>
                </Grid>
                <Grid item xs={12}>
                    <img src={WriteLicensePlateNumberPNG} alt="Write License Plate Number" />
                </Grid>
                <Grid item xs={12}>
                    <TextField onKeyUp={inputChangeHandler} onChange={(e) => setReporteDeVenta(e.target.value)} value={ReporteDeVenta} label="Reporte de venta" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/LastDigitsofVin">
                        <Button disabled={!buttonStatus}  className="ContinueButton" variant="contained" color="error" size="large" sx={{ width: "50%" }}>¿Renovar placas aquí?</Button>
                    </Link>
                </Grid>

                <RightClickMenu />
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;