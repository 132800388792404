import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    const [WouldYouLikeGetHidden, setWouldYouLikeGetHidden] = useLocalStorage("WouldYouLikeGetHidden", "");
    const navigate = useNavigate();
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
         <input type="hidden" value={WouldYouLikeGetHidden} />

            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/YouHaveThePlate">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">﻿¿Cuál es el valor del vehículo?</h2>
                </Grid>
                <Grid item xs={12}>        
                        <Button onClick={ () => { setWouldYouLikeGetHidden("Yes"); setTimeout(() => { navigate("/dmv/cambio/TextWithRatesRenewal")}, 100) }}  variant="contained" color="success" size="large" sx={{ width: "50%" }}>Consultar valor</Button>           
                </Grid>
                <Grid item xs={12}>                 
                        <Button onClick={ () => { setWouldYouLikeGetHidden("No"); setTimeout(() => { navigate("/dmv/cambio/TextWithRatesRenewal")}, 100) }}  variant="contained" color="error" size="large" sx={{ width: "50%" }}>$</Button>              
                </Grid>
                <Grid item xs={12}>                 
                        <Button onClick={ () => { setWouldYouLikeGetHidden("No"); setTimeout(() => { navigate("/dmv/cambio/IsVehicleInsured")}, 100) }}  variant="contained" color="error" size="large" sx={{ width: "50%" }}>Continuar</Button>              
                </Grid>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;