import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/RenovationCompletedShipmentGeneratedSeceond">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">﻿Parece que ya cuenta con algún otro proceso iniciado con DMV o con otro proveedor de servicios de DMV.</h2>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/DoYouKnowWhichPartProcessIncomplete">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Continuar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h5 className="subHeading">Cancelar</h5>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;