import RightClickMenu from "../components/rightClickMenu";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

import carPNG from '../img/car.png';

const AppBoardScreen = () => {
    
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WhatZipCodeCarRegistered">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Entiendo, tenemos suficiente tiempo y podemos esperar a que usted los consiga, en este momento le estoy compartiendo una imágen donde viene señalada la información que necesita y donde la puede encontrar. ¿En qué fecha consideras ya contarías con los documentos?</h2>
                </Grid>
                <Grid alignItems="center" item container spacing={2} sx={12}>
                    <Grid item xs={2}>
                        <img src={carPNG} alt="Car" />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/DoYouHaveFromRositaTitleDuplicate">
                        <Button className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>Agendar</Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <h2 className="subHeading">Cancelar</h2>
            </Grid>

            <RightClickMenu/>
            <Outlet/>
        </Box>
    );
};

export default AppBoardScreen ;