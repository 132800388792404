import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {
    const [Direccion, setDireccion] = useLocalStorage("Direccion", "");

    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (Direccion.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();

    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WhatCallContinueProcessSmog">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Dirección de envío</h2>
                </Grid>
                <Grid item xs={12}>
                    <h3 className="subHeading">?A que Direccion te mandamos los documentos?</h3>
                </Grid>
                <Grid item xs={12}>
                    <TextField  onKeyUp={inputChangeHandler} onChange={(e) => setDireccion(e.target.value)} value= {Direccion} label="Direccion" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/YourDmvFees">
                        <Button  disabled={!buttonStatus} className="ContinueButton" variant="contained" color="success" size="large" sx={{ width: "50%" }}>Continuar</Button>
                    </Link>
                </Grid>

                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;