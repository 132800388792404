import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/YourVehicleHasReportStolenSituation">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">No pudimos procesar tu pago</h2>
                </Grid>
                <Grid item xs={12}>
                    <h3 className="subHeading">ERROR CODE</h3>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Intentar de nuevo</Button>
                    </Link>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;