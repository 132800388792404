import './App.css';

import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Box from '@mui/material/Box';

import MainMenu from "./pages/MainMenu.js";
import MainMenuSub from "./pages/MainMenuSub.js";

import AgentplacasHome from "./pages/agentplacas/home.js";
import AgentplacasServicioNecesita from "./pages/agentplacas/ServicioNecesita.js";
import AgentplacasRegistrationRequireInfo from "./pages/agentplacas/RegistrationRequireInfo.js";
import AgentplacasIsVehicleRegisteredStateCalifornia from "./pages/agentplacas/IsVehicleRegisteredStateCalifornia.js";
import AgentplacasIsVehicleRegisteredStateCaliforniaNo from "./pages/agentplacas/IsVehicleRegisteredStateCaliforniaNo.js";
import AgentplacasWhatZipCodeCarRegistered from "./pages/agentplacas/WhatZipCodeCarRegistered.js";
import AgentplacasWhatTypeVehicle from "./pages/agentplacas/WhatTypeVehicle.js";
import AgentplacasHaveLicensePlateNumber from "./pages/agentplacas/HaveLicensePlateNumber.js";
import AgentplacasWriteLicensePlateNumber from "./pages/agentplacas/WriteLicensePlateNumber.js";
import AgentplacasLastDigitsofVin from "./pages/agentplacas/LastDigitsofVin.js";
import AgentplacasThisYourVehicle from "./pages/agentplacas/ThisYourVehicle.js";
import AgentplacasYouHaveThePlate from "./pages/agentplacas/YouHaveThePlate.js";
import AgentplacasWouldYouLikeGet from "./pages/agentplacas/WouldYouLikeGet.js";
import AgentplacasTextWithRatesRenewal from "./pages/agentplacas/TextWithRatesRenewal.js";
import AgentplacasElectronicReceiptShippingUpdates from "./pages/agentplacas/ElectronicReceiptShippingUpdates.js";
import AgentplacasWhatYourEmail from "./pages/agentplacas/WhatYourEmail.js";
import AgentplacasEducateTheCustomer from "./pages/agentplacas/EducateTheCustomer.js";
import AgentplacasCustomerName from "./pages/agentplacas/CustomerName.js";
import AgentplacasTheCompanyOnlyLicensedWithinCalifornia from "./pages/agentplacas/TheCompanyOnlyLicensedWithinCalifornia.js";
import AgentplacasSinceYouDonoThaveYourLicensePlate from "./pages/agentplacas/SinceYouDonoThaveYourLicensePlate.js";
import AgentplacasEducateCustomerThatCompleteNecessarySince from "./pages/agentplacas/EducateCustomerThatCompleteNecessarySince.js";
import AgentplacasCurrentlyYourProcessRequirePerformingSmog from "./pages/agentplacas/CurrentlyYourProcessRequirePerformingSmog.js";
import AgentplacasHaveYouDoneTheSmogYet  from "./pages/agentplacas/HaveYouDoneTheSmogYet.js";
import AgentplacasDoyouWantMakePaymentAvoidCharges from "./pages/agentplacas/DoyouWantMakePaymentAvoidCharges.js";
import AgentplacasSystemStillNotReflectingYourSmogNeed from "./pages/agentplacas/SystemStillNotReflectingYourSmogNeed.js";
import AgentplacasWhatCallContinueProcessSmog from "./pages/agentplacas/WhatCallContinueProcessSmog.js";
import AgentplacasShippingAddress from "./pages/agentplacas/ShippingAddress.js";
import AgentplacasYourDmvFees from "./pages/agentplacas/YourDmvFees.js";
import AgentplacasTakeThePayment from "./pages/agentplacas/TakeThePayment.js";
import AgentplacasItSeemsCarriedAnotherProcessDuringSameDay from "./pages/agentplacas/ItSeemsCarriedAnotherProcessDuringSameDay.js";
import AgentplacasYourVehicleHasReportStolenSituation from "./pages/agentplacas/YourVehicleHasReportStolenSituation.js";
import AgentplacasWeWereUnableProcess from "./pages/agentplacas/WeWereUnableProcess.js";
import AgentplacasRenovationCompletedShipmentGenerated from "./pages/agentplacas/RenovationCompletedShipmentGenerated.js";
import AgentplacasYourRegistrationCurrentlySuspended from "./pages/agentplacas/YourRegistrationCurrentlySuspended.js";
import AgentplacasIsVehicleInsured from "./pages/agentplacas/IsVehicleInsured.js";
import AgentplacasToRemoveSuspensionNecessary from "./pages/agentplacas/ToRemoveSuspensionNecessary.js";
import AgentplacasWhatDayWhatTime from "./pages/agentplacas/WhatDayWhatTime.js";
import AgentplacasNameVin from "./pages/agentplacas/NameVin.js";
import AgentplacasWouldLikeRemoveSuspension from "./pages/agentplacas/WouldLikeRemoveSuspension.js";
import AgentplacasInviteCustomerReachBackOnce from "./pages/agentplacas/InviteCustomerReachBackOnce.js";
import AgentplacasShippingAddressSeceond from "./pages/agentplacas/ShippingAddressSeceond.js";
import AgentplacasYourDmvFeesSeceond from "./pages/agentplacas/YourDmvFeesSeceond.js";
import AgentplacasTakeThePaymentSeceond from "./pages/agentplacas/TakeThePaymentSeceond.js";
import AgentplacasWeWereUnableProcessSecenod from "./pages/agentplacas/WeWereUnableProcessSecenod.js";
import AgentplacasRenovationCompletedShipmentGeneratedSeceond from "./pages/agentplacas/RenovationCompletedShipmentGeneratedSeceond.js";
import AgentplacasItLooksLikeAlreadySomeOtherProcesStarted from "./pages/agentplacas/ItLooksLikeAlreadySomeOtherProcesStarted.js";
import AgentplacasDoYouKnowWhichPartProcessIncomplete from "./pages/agentplacas/DoYouKnowWhichPartProcessIncomplete.js";
import AgentplacasInOrderContinueProcessFinishProcesSpending from "./pages/agentplacas/InOrderContinueProcessFinishProcesSpending.js";
import AgentplacasOrderCarryOutHistoryAdditionalPreventing from "./pages/agentplacas/OrderCarryOutHistoryAdditionalPreventing.js";
import AgentplacasWhatDayWhatContinue from "./pages/agentplacas/WhatDayWhatContinue.js";

import AgentcambioHome from "./pages/agentcambio/home.js";
import AgentcambioServicioNecesitaNeedToday from "./pages/agentcambio/ServicioNecesitaNeedToday.js";
import AgentcambioRegistrationRequireInfo from "./pages/agentcambio/RegistrationRequireInfo.js";
import AgentcambioIsVehicleRegisteredStateCalifornia from "./pages/agentcambio/IsVehicleRegisteredStateCalifornia.js";
import AgentcambioIsVehicleRegisteredStateCaliforniaNo from "./pages/agentcambio/IsVehicleRegisteredStateCaliforniaNo.js";
import AgentcambioWhatZipCodeCarRegistered from "./pages/agentcambio/WhatZipCodeCarRegistered.js";
import AgentcambioWhatTypeVehicle from "./pages/agentcambio/WhatTypeVehicle.js";
import AgentcambioHaveLicensePlateNumber from "./pages/agentcambio/HaveLicensePlateNumber.js";
import AgentcambioWriteLicensePlateNumber from "./pages/agentcambio/WriteLicensePlateNumber.js";
import AgentcambioLastDigitsofVin from "./pages/agentcambio/LastDigitsofVin.js";
import AgentcambioThisYourVehicle from "./pages/agentcambio/ThisYourVehicle.js";
import AgentcambioYouHaveThePlate from "./pages/agentcambio/YouHaveThePlate.js";
import AgentcambioWouldYouLikeGet from "./pages/agentcambio/WouldYouLikeGet.js";
import AgentcambioTextWithRatesRenewal from "./pages/agentcambio/TextWithRatesRenewal.js";
import AgentcambioElectronicReceiptShippingUpdates from "./pages/agentcambio/ElectronicReceiptShippingUpdates.js";
import AgentcambioWhatYourEmail from "./pages/agentcambio/WhatYourEmail.js";
import AgentcambioEducateTheCustomer from "./pages/agentcambio/EducateTheCustomer.js";
import AgentcambioCustomerName from "./pages/agentcambio/CustomerName.js";
import AgentcambioWouldLikeGetReplacement from "./pages/agentcambio/WouldLikeGetReplacement.js";
import AgentcambioSinceYouDonoThaveYourLicensePlate from "./pages/agentcambio/SinceYouDonoThaveYourLicensePlate.js";
import AgentcambioEducateCustomerThatCompleteNecessarySince from "./pages/agentcambio/EducateCustomerThatCompleteNecessarySince.js";
import AgentcambioCurrentlyYourProcessRequirePerformingSmog from "./pages/agentcambio/CurrentlyYourProcessRequirePerformingSmog.js";
import AgentcambioHaveYouDoneTheSmogYet  from "./pages/agentcambio/HaveYouDoneTheSmogYet.js";
import AgentcambioDoyouWantMakePaymentAvoidCharges from "./pages/agentcambio/DoyouWantMakePaymentAvoidCharges.js";
import AgentcambioSystemStillNotReflectingYourSmogNeed from "./pages/agentcambio/SystemStillNotReflectingYourSmogNeed.js";
import AgentcambioWhatCallContinueProcessSmog from "./pages/agentcambio/WhatCallContinueProcessSmog.js";
import AgentcambioShippingAddress from "./pages/agentcambio/ShippingAddress.js";
import AgentcambioYourDmvFees from "./pages/agentcambio/YourDmvFees.js";
import AgentcambioTakeThePayment from "./pages/agentcambio/TakeThePayment.js";
import AgentcambioItSeemsCarriedAnotherProcessDuringSameDay from "./pages/agentcambio/ItSeemsCarriedAnotherProcessDuringSameDay.js";
import AgentcambioYourVehicleHasReportStolenSituation from "./pages/agentcambio/YourVehicleHasReportStolenSituation.js";
import AgentcambioWeWereUnableProcess from "./pages/agentcambio/WeWereUnableProcess.js";
import AgentcambioRenovationCompletedShipmentGenerated from "./pages/agentcambio/RenovationCompletedShipmentGenerated.js";
import AgentcambioYourRegistrationCurrentlySuspended from "./pages/agentcambio/YourRegistrationCurrentlySuspended.js";
import AgentcambioIsVehicleInsured from "./pages/agentcambio/IsVehicleInsured.js";
import AgentcambioToRemoveSuspensionNecessary from "./pages/agentcambio/ToRemoveSuspensionNecessary.js";
import AgentcambioWhatDayWhatTime from "./pages/agentcambio/WhatDayWhatTime.js";
import AgentcambioNameVin from "./pages/agentcambio/NameVin.js";
import AgentcambioWouldLikeRemoveSuspension from "./pages/agentcambio/WouldLikeRemoveSuspension.js";
import AgentcambioInviteCustomerReachBackOnce from "./pages/agentcambio/InviteCustomerReachBackOnce.js";
import AgentcambioShippingAddressSeceond from "./pages/agentcambio/ShippingAddressSeceond.js";
import AgentcambioYourDmvFeesSeceond from "./pages/agentcambio/YourDmvFeesSeceond.js";
import AgentcambioTakeThePaymentSeceond from "./pages/agentcambio/TakeThePaymentSeceond.js";
import AgentcambioWeWereUnableProcessSecenod from "./pages/agentcambio/WeWereUnableProcessSecenod.js";
import AgentcambioRenovationCompletedShipmentGeneratedSeceond from "./pages/agentcambio/RenovationCompletedShipmentGeneratedSeceond.js";
import AgentcambioItLooksLikeAlreadySomeOtherProcesStarted from "./pages/agentcambio/ItLooksLikeAlreadySomeOtherProcesStarted.js";
import AgentcambioDoYouKnowWhichPartProcessIncomplete from "./pages/agentcambio/DoYouKnowWhichPartProcessIncomplete.js";
import AgentcambioInOrderContinueProcessFinishProcesSpending from "./pages/agentcambio/InOrderContinueProcessFinishProcesSpending.js";
import AgentcambioOrderCarryOutHistoryAdditionalPreventing from "./pages/agentcambio/OrderCarryOutHistoryAdditionalPreventing.js";
import AgentcambioWhatDayWhatContinue from "./pages/agentcambio/WhatDayWhatContinue.js";
import AgentcambioIUnderstandButWorry from "./pages/agentcambio/IUnderstandButWorry.js";
import AgentcambioItImportanTheChangeStatus from "./pages/agentcambio/ItImportanTheChangeStatus.js";
import AgentcambioForThisWeWillNeedYouProvideWithYourPostal from "./pages/agentcambio/ForThisWeWillNeedYouProvideWithYourPostal.js";
import AgentcambioToHavereFerenceGoingVerify from "./pages/agentcambio/ToHavereFerenceGoingVerify.js";
import AgentcambioToHavereFerenceGoingVerifytwo from "./pages/agentcambio/ToHavereFerenceGoingVerifytwo.js";
import AgentcambioWhatYearIstheVehicle from "./pages/agentcambio/WhatYearIstheVehicle.js";
import AgentcambioDoYouHaveBillOfSale from "./pages/agentcambio/DoYouHaveBillOfSale.js";
import AgentcambioDoYouHaveFromRositaTitleDuplicate from "./pages/agentcambio/DoYouHaveFromRositaTitleDuplicate.js";
import AgentcambioContactPreviousOwner from "./pages/agentcambio/ContactPreviousOwner.js";
import AgentcambioProvideWithDocuments from "./pages/agentcambio/ProvideWithDocuments.js";
import AgentcambioSharingImageInformation from "./pages/agentcambio/SharingImageInformation.js";
import AgentcambioWithNameChangeProcess from "./pages/agentcambio/WithNameChangeProcess.js";

function App() { return(

  <div className="App">
    <Box className="AppBoard" sx={{ display: "flex", height: "100vh" }}>    

      <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<MainMenu />} />
          <Route path="dmv/" element={<MainMenuSub />} />

          <Route path="dmv/placas/" element={<AgentplacasHome />} />
          <Route path="dmv/placas/ServicioNecesita" element={<AgentplacasServicioNecesita />} />
          <Route path="dmv/placas/RegistrationRequireInfo" element={<AgentplacasRegistrationRequireInfo />} />
          <Route path="dmv/placas/IsVehicleRegisteredStateCalifornia" element={<AgentplacasIsVehicleRegisteredStateCalifornia />} />
          <Route path="dmv/placas/IsVehicleRegisteredStateCaliforniaNo" element={<AgentplacasIsVehicleRegisteredStateCaliforniaNo />} />
          <Route path="dmv/placas/WhatZipCodeCarRegistered" element={<AgentplacasWhatZipCodeCarRegistered />} />
          <Route path="dmv/placas/WhatTypeVehicle" element={<AgentplacasWhatTypeVehicle />} />
          <Route path="dmv/placas/HaveLicensePlateNumber" element={<AgentplacasHaveLicensePlateNumber />} />
          <Route path="dmv/placas/WriteLicensePlateNumber" element={<AgentplacasWriteLicensePlateNumber />} />
          <Route path="dmv/placas/LastDigitsofVin" element={<AgentplacasLastDigitsofVin />} />
          <Route path="dmv/placas/ThisYourVehicle" element={<AgentplacasThisYourVehicle />} />
          <Route path="dmv/placas/YouHaveThePlate" element={<AgentplacasYouHaveThePlate/>} />
          <Route path="dmv/placas/WouldYouLikeGete" element={<AgentplacasWouldYouLikeGet/>} />
          <Route path="dmv/placas/TextWithRatesRenewal" element={<AgentplacasTextWithRatesRenewal/>} />
          <Route path="dmv/placas/ElectronicReceiptShippingUpdates" element={<AgentplacasElectronicReceiptShippingUpdates/>} />
          <Route path="dmv/placas/WhatYourEmail" element={<AgentplacasWhatYourEmail/>} />
          <Route path="dmv/placas/EducateTheCustomer" element={<AgentplacasEducateTheCustomer/>} />
          <Route path="dmv/placas/CustomerName" element={<AgentplacasCustomerName/>} />
          <Route path="dmv/placas/placas/TheCompanyOnlyLicensedWithinCalifornia" element={<AgentplacasTheCompanyOnlyLicensedWithinCalifornia/>} />
          <Route path="dmv/placas/SinceYouDonoThaveYourLicensePlate" element={<AgentplacasSinceYouDonoThaveYourLicensePlate/>} />
          <Route path="dmv/placas/EducateCustomerThatCompleteNecessarySince" element={<AgentplacasEducateCustomerThatCompleteNecessarySince />} />
          <Route path="dmv/placas/CurrentlyYourProcessRequirePerformingSmog" element={<AgentplacasCurrentlyYourProcessRequirePerformingSmog />} />
          <Route path="dmv/placas/HaveYouDoneTheSmogYet" element={<AgentplacasHaveYouDoneTheSmogYet/>} />
          <Route path="dmv/placas/DoyouWantMakePaymentAvoidCharges" element={<AgentplacasDoyouWantMakePaymentAvoidCharges/>} />
          <Route path="dmv/placas/SystemStillNotReflectingYourSmogNeed" element={<AgentplacasSystemStillNotReflectingYourSmogNeed/>} />
          <Route path="dmv/placas/WhatCallContinueProcessSmog" element={<AgentplacasWhatCallContinueProcessSmog/>} />
          <Route path="dmv/placas/ShippingAddress" element={<AgentplacasShippingAddress/>} />
          <Route path="dmv/placas/YourDmvFees" element={<AgentplacasYourDmvFees/>} />
          <Route path="dmv/placas/TakeThePayment" element={<AgentplacasTakeThePayment/>} />
          <Route path="dmv/placas/ItSeemsCarriedAnotherProcessDuringSameDay" element={<AgentplacasItSeemsCarriedAnotherProcessDuringSameDay/>} />
          <Route path="dmv/placas/YourVehicleHasReportStolenSituation" element={<AgentplacasYourVehicleHasReportStolenSituation />} />
          <Route path="dmv/placas/WeWereUnableProcess" element={<AgentplacasWeWereUnableProcess />} />
          <Route path="dmv/placas/RenovationCompletedShipmentGenerated" element={<AgentplacasRenovationCompletedShipmentGenerated />} />
          <Route path="dmv/placas/YourRegistrationCurrentlySuspended" element={<AgentplacasYourRegistrationCurrentlySuspended />} />
          <Route path="dmv/placas/IsVehicleInsured" element={<AgentplacasIsVehicleInsured />} />
          <Route path="dmv/placas/ToRemoveSuspensionNecessary" element={<AgentplacasToRemoveSuspensionNecessary/>} />
          <Route path="dmv/placas/WhatDayWhatTime" element={<AgentplacasWhatDayWhatTime/>} />
          <Route path="dmv/placas/NameVin" element={<AgentplacasNameVin/>} />
          <Route path="dmv/placas/WouldLikeRemoveSuspension" element={<AgentplacasWouldLikeRemoveSuspension/>} />
          <Route path="dmv/placas/InviteCustomerReachBackOnce" element={<AgentplacasInviteCustomerReachBackOnce/>} />
          <Route path="dmv/placas/ShippingAddressSeceond" element={<AgentplacasShippingAddressSeceond/>} />
          <Route path="dmv/placas/YourDmvFeesSeceond" element={<AgentplacasYourDmvFeesSeceond/>} />
          <Route path="dmv/placas/TakeThePaymentSeceond" element={<AgentplacasTakeThePaymentSeceond/>} />
          <Route path="dmv/placas/WeWereUnableProcessSecenod" element={<AgentplacasWeWereUnableProcessSecenod/>} />
          <Route path="dmv/placas/RenovationCompletedShipmentGeneratedSeceond" element={<AgentplacasRenovationCompletedShipmentGeneratedSeceond/>} />
          <Route path="dmv/placas/ItLooksLikeAlreadySomeOtherProcesStarted" element={<AgentplacasItLooksLikeAlreadySomeOtherProcesStarted/>} />
          <Route path="dmv/placas/DoYouKnowWhichPartProcessIncomplete" element={<AgentplacasDoYouKnowWhichPartProcessIncomplete/>} />
          <Route path="dmv/placas/InOrderContinueProcessFinishProcesSpending" element={<AgentplacasInOrderContinueProcessFinishProcesSpending/>} />
          <Route path="dmv/placas/OrderCarryOutHistoryAdditionalPreventing" element={<AgentplacasOrderCarryOutHistoryAdditionalPreventing/>} />
          <Route path="dmv/placas/WhatDayWhatContinue" element={<AgentplacasWhatDayWhatContinue/>} />

          <Route path="dmv/cambio/" element={<AgentcambioHome />} />
          <Route path="dmv/cambio/ServicioNecesitaNeedToday" element={<AgentcambioServicioNecesitaNeedToday/>} />
          <Route path="dmv/cambio/RegistrationRequireInfo" element={<AgentcambioRegistrationRequireInfo />} />
          <Route path="dmv/cambio/IsVehicleRegisteredStateCalifornia" element={<AgentcambioIsVehicleRegisteredStateCalifornia />} />
          <Route path="dmv/cambio/IsVehicleRegisteredStateCaliforniaNo" element={<AgentcambioIsVehicleRegisteredStateCaliforniaNo />} />
          <Route path="dmv/cambio/WhatZipCodeCarRegistered" element={<AgentcambioWhatZipCodeCarRegistered />} />
          <Route path="dmv/cambio/WhatTypeVehicle" element={<AgentcambioWhatTypeVehicle />} />
          <Route path="dmv/cambio/HaveLicensePlateNumber" element={<AgentcambioHaveLicensePlateNumber />} />
          <Route path="dmv/cambio/WriteLicensePlateNumber" element={<AgentcambioWriteLicensePlateNumber />} />
          <Route path="dmv/cambio/ThisYourVehicle" element={<AgentcambioThisYourVehicle />} />
          <Route path="dmv/cambio/YouHaveThePlate" element={<AgentcambioYouHaveThePlate/>} />
          <Route path="dmv/cambio/WouldYouLikeGete" element={<AgentcambioWouldYouLikeGet/>} />
          <Route path="dmv/cambio/TextWithRatesRenewal" element={<AgentcambioTextWithRatesRenewal/>} />
          <Route path="dmv/cambio/ElectronicReceiptShippingUpdates" element={<AgentcambioElectronicReceiptShippingUpdates/>} />
          <Route path="dmv/cambio/WhatYourEmail" element={<AgentcambioWhatYourEmail/>} />
          <Route path="dmv/cambio/EducateTheCustomer" element={<AgentcambioEducateTheCustomer/>} />
          <Route path="dmv/cambio/CustomerName" element={<AgentcambioCustomerName/>} />
          <Route path="dmv/cambio/wouldLikeGetReplacement$23" element={<AgentcambioWouldLikeGetReplacement/>} />
          <Route path="dmv/cambio/SinceYouDonoThaveYourLicensePlate" element={<AgentcambioSinceYouDonoThaveYourLicensePlate/>} />
          <Route path="dmv/cambio/EducateCustomerThatCompleteNecessarySince" element={<AgentcambioEducateCustomerThatCompleteNecessarySince />} />
          <Route path="dmv/cambio/CurrentlyYourProcessRequirePerformingSmog" element={<AgentcambioCurrentlyYourProcessRequirePerformingSmog />} />
          <Route path="dmv/cambio/HaveYouDoneTheSmogYet" element={<AgentcambioHaveYouDoneTheSmogYet/>} />
          <Route path="dmv/cambio/DoyouWantMakePaymentAvoidCharges" element={<AgentcambioDoyouWantMakePaymentAvoidCharges/>} />
          <Route path="dmv/cambio/SystemStillNotReflectingYourSmogNeed" element={<AgentcambioSystemStillNotReflectingYourSmogNeed/>} />
          <Route path="dmv/cambio/WhatCallContinueProcessSmog" element={<AgentcambioWhatCallContinueProcessSmog/>} />
          <Route path="dmv/cambio/ShippingAddress" element={<AgentcambioShippingAddress/>} />
          <Route path="dmv/cambio/YourDmvFees" element={<AgentcambioYourDmvFees/>} />
          <Route path="dmv/cambio/TakeThePayment" element={<AgentcambioTakeThePayment/>} />
          <Route path="dmv/cambio/ItSeemsCarriedAnotherProcessDuringSameDay" element={<AgentcambioItSeemsCarriedAnotherProcessDuringSameDay/>} />
          <Route path="dmv/cambio/YourVehicleHasReportStolenSituation" element={<AgentcambioYourVehicleHasReportStolenSituation />} />
          <Route path="dmv/cambio/WeWereUnableProcess" element={<AgentcambioWeWereUnableProcess />} />
          <Route path="dmv/cambio/RenovationCompletedShipmentGenerated" element={<AgentcambioRenovationCompletedShipmentGenerated />} />
          <Route path="dmv/cambio/YourRegistrationCurrentlySuspended" element={<AgentcambioYourRegistrationCurrentlySuspended />} />
          <Route path="dmv/cambio/IsVehicleInsured" element={<AgentcambioIsVehicleInsured />} />
          <Route path="dmv/cambio/ToRemoveSuspensionNecessary" element={<AgentcambioToRemoveSuspensionNecessary/>} />
          <Route path="dmv/cambio/WhatDayWhatTime" element={<AgentcambioWhatDayWhatTime/>} />
          <Route path="dmv/cambio/NameVin" element={<AgentcambioNameVin/>} />
          <Route path="dmv/cambio/WouldLikeRemoveSuspension" element={<AgentcambioWouldLikeRemoveSuspension/>} />
          <Route path="dmv/cambio/InviteCustomerReachBackOnce" element={<AgentcambioInviteCustomerReachBackOnce/>} />
          <Route path="dmv/cambio/ShippingAddressSeceond" element={<AgentcambioShippingAddressSeceond/>} />
          <Route path="dmv/cambio/YourDmvFeesSeceond" element={<AgentcambioYourDmvFeesSeceond/>} />
          <Route path="dmv/cambio/TakeThePaymentSeceond" element={<AgentcambioTakeThePaymentSeceond/>} />
          <Route path="dmv/cambio/WeWereUnableProcessSecenod" element={<AgentcambioWeWereUnableProcessSecenod/>} />
          <Route path="dmv/cambio/RenovationCompletedShipmentGeneratedSeceond" element={<AgentcambioRenovationCompletedShipmentGeneratedSeceond/>} />
          <Route path="dmv/cambio/ItLooksLikeAlreadySomeOtherProcesStarted" element={<AgentcambioItLooksLikeAlreadySomeOtherProcesStarted/>} />
          <Route path="dmv/cambio/DoYouKnowWhichPartProcessIncomplete" element={<AgentcambioDoYouKnowWhichPartProcessIncomplete/>} />
          <Route path="dmv/cambio/InOrderContinueProcessFinishProcesSpending" element={<AgentcambioInOrderContinueProcessFinishProcesSpending/>} />
          <Route path="dmv/cambio/OrderCarryOutHistoryAdditionalPreventing" element={<AgentcambioOrderCarryOutHistoryAdditionalPreventing/>} />
          <Route path="dmv/cambio/WhatDayWhatContinue" element={<AgentcambioWhatDayWhatContinue/>} />
          <Route path="dmv/cambio/IUnderstandButWorry" element={<AgentcambioIUnderstandButWorry/>} />
          <Route path="dmv/cambio/ItImportanTheChangeStatus" element={<AgentcambioItImportanTheChangeStatus/>} />
          <Route path="dmv/cambio/ForThisWeWillNeedYouProvideWithYourPostal" element={<AgentcambioForThisWeWillNeedYouProvideWithYourPostal/>} />
          <Route path="dmv/cambio/ToHavereFerenceGoingVerify" element={<AgentcambioToHavereFerenceGoingVerify/>} />
          <Route path="dmv/cambio/ToHavereFerenceGoingVerifytwo" element={<AgentcambioToHavereFerenceGoingVerifytwo/>} />
          <Route path="dmv/cambio/WhatYearIstheVehicle" element={<AgentcambioWhatYearIstheVehicle/>} />
          <Route path="dmv/cambio/DoYouHaveBillOfSale" element={<AgentcambioDoYouHaveBillOfSale/>} />
          <Route path="dmv/cambio/DoYouHaveFromRositaTitleDuplicate" element={<AgentcambioDoYouHaveFromRositaTitleDuplicate/>} />
          <Route path="dmv/cambio/ContactPreviousOwner" element={<AgentcambioContactPreviousOwner/>} />
          <Route path="dmv/cambio/ProvideWithDocuments" element={<AgentcambioProvideWithDocuments/>} />
          <Route path="dmv/cambio/SharingImageInformation" element={<AgentcambioSharingImageInformation/>} />
          <Route path="dmv/cambio/WithNameChangeProcess" element={<AgentcambioWithNameChangeProcess/>} />
          <Route path="dmv/cambio/LastDigitsofVin" element={<AgentcambioLastDigitsofVin/>} />
          
        </Routes>
      </BrowserRouter>
      
    </Box>
  </div>

); }

export default App;
