import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    const [HaveLicensePlateNumberHidden, setHaveLicensePlateNumberHidden] = useLocalStorage("HaveLicensePlateNumberHidden", "");
    const navigate = useNavigate();
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <input type="hidden" value={HaveLicensePlateNumberHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WhatTypeVehicle">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Tiene su número de placa con usted?</h2>
                </Grid>
                <Grid item xs={12}>              
                    <Button onClick={ () => { setHaveLicensePlateNumberHidden("Yes"); setTimeout(() => { navigate("/dmv/cambio/WriteLicensePlateNumber")}, 100) }}  variant="contained" color="success" size="large" sx={{ width: "50%" }}>Si</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={ () => { setHaveLicensePlateNumberHidden("No"); setTimeout(() => { navigate("/dmv/cambio/SinceYouDonoThaveYourLicensePlate")}, 100) }}  variant="contained" color="error" size="large" sx={{ width: "50%" }}>No</Button>                
                </Grid>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;