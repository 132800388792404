import { useLocalStorage } from "../components/useLocalStorage";
import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {

    const [AñadeNombreCompleto, setAñadeNombreCompleto] = useLocalStorage("AñadeNombreCompleto", "");

    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (AñadeNombreCompleto.length > 0) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();
    
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/EducateTheCustomer">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Nombre del cliente</h2>
                </Grid>
                <Grid item xs={12}>
                    <TextField onKeyUp={inputChangeHandler} onChange={(e) => setAñadeNombreCompleto(e.target.value)} value= {AñadeNombreCompleto} label="Añade nombre completo" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Señala la fecha y hora</h2>
                </Grid>
                <Grid item xs={12}>
                <h2 className="subHeading">Razón</h2>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        value={10}
                        labelId="demo-simple-select-label"
                        label="Seleccionar">
                        <MenuItem value={10}>1:No cuenta con información a la mano</MenuItem>
                        <MenuItem value={20}>2:No cuenta con el # VIN completo puesto que no tiene la placa consigo </MenuItem>
                        <MenuItem value={30}>3:No cuenta con los últimos 3 digitos del # del VIN posterior al # de placa</MenuItem>
                        <MenuItem value={30}>4:El cliente ya realizó el SMOG pero no se encuentra en sistema </MenuItem>
                        <MenuItem value={30}>5:El cliente no ha realizado el SMOG y no quizó pagar para evitar cargos </MenuItem>
                        <MenuItem value={30}>6:Buscara seguro por otra parte pero quiere remover la suspensión con nosotros.</MenuItem>
                        <MenuItem value={30}>7:Ya tenía un proceso iniciado con el DMV</MenuItem>

                    </Select>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <Link to="/dmv/cambio/IsVehicleRegisteredStateCalifornia">
                        <Button disabled={!buttonStatus} className="NextButton" variant="contained" size="large">Próxima</Button>
                    </Link>
                </Grid>
                <Outlet />

            </Grid>
        </Box>
    );
};

export default AppBoardScreen;