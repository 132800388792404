import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import { Outlet, Link } from "react-router-dom";

const Home = () => {
    return (
        <Box id="AppBoardStart" className="AppBoardScreen AppBoardStart" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <h1 className="mainHeading">Inicio de la llamada</h1>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/ServicioNecesitaNeedToday">
                        <Button variant="contained" endIcon={<SendIcon />}>Start</Button>
                    </Link>
                </Grid>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default Home;