import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';

import { useLocalStorage } from "../components/useLocalStorage";
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {
    const [LastDigitsVin, setLastDigitsVin] = useLocalStorage("LastDigitsVin", "");

    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (LastDigitsVin.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };

    inputChangeHandler();
    
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WriteLicensePlateNumber">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Número del VIN completoN</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="large" sx={{ background: "#000", color: "#FFF", width: "50%", "&:hover": { background: "#000", color: "#FFF" } }}>0000 VIN 000</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField onKeyUp={inputChangeHandler} onChange={(e) => setLastDigitsVin(e.target.value)} value= {LastDigitsVin} label="Ultimos 17 digitos del VIN" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/ThisYourVehicle">
                        <Button disabled={!buttonStatus} className="ContinueButton" variant="contained" color="error" size="large" sx={{ width: "50%" }}>Continuar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <HelpIcon sx={{ "width": "4em", "height": "4em" }} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/EducateCustomerThatCompleteNecessarySince">
                        <h4 className="subHeading">No cuento con el VIN</h4>
                    </Link>
                </Grid>

                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;