import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {
    const [Name, setName] = useLocalStorage("Name", "");
    const [Vin, setVin] = useLocalStorage("Vin", "");
    const [PhoneNumber, setPhoneNumber] = useLocalStorage("PhoneNumber", "");
    const [Address, setAddress] = useLocalStorage("Address", "");
    const [TypeAutoCommercial, setTypeAutoCommercial] = useLocalStorage("TypeAutoCommercial", "");

    /*
    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (LastDigitsVin.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };
    */
   
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/cambio/WhatDayWhatTime">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => setName(e.target.value)} value={Name} label="Name" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => setVin(e.target.value)} value={Vin} label="VIN" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => setPhoneNumber(e.target.value)} value={PhoneNumber} label="Phone number" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => setAddress(e.target.value)} value={Address} label="Address" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => setTypeAutoCommercial(e.target.value)} value={TypeAutoCommercial} label="Type (auto, commercial, recreational, etc.)" variant="outlined" sx={TextFieldStyle} />
                </Grid>

                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;