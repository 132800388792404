import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

const screen2 = () => {
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                <Link to="/dmv/cambio/TakeThePayment">
                        <Button variant="outlined" size="large">Back</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Parece ser que realizó otro proceso durante el mismo día, como solo se puede hacer una transacción por día necesitaremos reagendar para darle continuidad dentro de 24 horas ¿Preferiría que le llamemos a esta misma hora o a otra? </h2>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/cambio/CustomerName">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Agendar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h5 className="subHeading">Cancelar</h5>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default screen2;