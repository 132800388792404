import RightClickMenu from "../components/rightClickMenu";
import { useLocalStorage } from "../components/useLocalStorage";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Outlet, Link } from "react-router-dom";

const TextFieldStyle = { width: '100%' };

const AppBoardScreen = () => {
    const [IngresaLosDígitosDelVin, setIngresaLosDígitosDelVin] = useLocalStorage("IngresaLosDígitosDelVin", "");

    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/TheCompanyOnlyLicensedWithinCalifornia">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">﻿Puesto que no cuenta con su placa necesitaremos que nos brinde su VIN completo para continuar con el</h2>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" size="large" sx={{ background: "#000", color: "#FFF", width: "50%", "&:hover": { background: "#000", color: "#FFF" } }}>0000 VIN 000</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField onChange={(e) => setIngresaLosDígitosDelVin(e.target.value)} value={IngresaLosDígitosDelVin} label="Ingresa los 17 dígitos del VIN" variant="outlined" sx={TextFieldStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/placas/ThisYourVehicle">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Continuar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/placas/EducateCustomerThatCompleteNecessarySince">
                        <h4 className="subHeading">﻿No cuento con el VIN</h4>
                    </Link>
                </Grid>

                <RightClickMenu />
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;