import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import EventIcon from '@mui/icons-material/Event';

import { Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/SinceYouDonoThaveYourLicensePlate">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">﻿Educar al cliente que el VIN completo es necesario puesto que no cuenta con la placa, que reagendaremos para darle continuidad, recolectar su nombre completo </h2>
                </Grid>
                <Grid item xs={12}>
                    <EventIcon sx={{ "width": "4em", "height": "4em" }} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/placas/LastDigitsofVin">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Agendar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/">
                        <h5 className="subHeading">﻿Cancelar</h5>
                    </Link>
                </Grid>

                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;