import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    const [WouldLikeRemoveSuspensionHidden, setWouldLikeRemoveSuspensionHidden] = useLocalStorage("WouldLikeRemoveSuspensionHidden", "");
    const navigate = useNavigate();
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
             <input type="hidden" value={WouldLikeRemoveSuspensionHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/NameVin">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Le gustaría remover la suspensión por $15 más? </h2>
                </Grid>
                <Grid item xs={12}>           
                    <Button onClick={ () => { setWouldLikeRemoveSuspensionHidden("Yes"); setTimeout(() => { navigate("/dmv/placas/IsVehicleRegisteredStateCalifornia")}, 100) }} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Si</Button>
                </Grid>
                <Grid item xs={12}>                 
                    <Button onClick={ () => { setWouldLikeRemoveSuspensionHidden("No"); setTimeout(() => { navigate("/dmv/placas/InviteCustomerReachBackOnce")}, 100) }} variant="contained" color="error" size="large" sx={{ width: "50%" }}>No</Button>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;