import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import Links from '@mui/material/Link';
import EventIcon from '@mui/icons-material/Event';

import { Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/RegistrationRequireInfo">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Entiendo, no se preocupe podemos seguir ayudándolo, le comento que es importante contar con la información a la mano para continuar con el proceso puesto que la requeriremos más adelante. Le estoy compartiendo una imagen donde viene la información que necesitamos y cómo la puede conseguir.<br/><br/>﻿¿Esta bien si le marco en una hora para ver si ya lo tiene?</h2>
                </Grid>
                <Grid item xs={12}>
                    <EventIcon sx={{ "width": "4em", "height": "4em" }} />
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Agendar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Links href="/dmv/" target="_blank" rel="noreferrer" underline="always">{'Insurance'}</Links>
                </Grid>
                
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;