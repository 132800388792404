import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { Outlet, Link } from "react-router-dom";

const AppBoardScreen = () => {
    return (
        <Box id="AppBoardScreen1" className="AppBoardScreen AppBoardScreen1" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/WouldLikeRemoveSuspension">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">Invite the customer to reach back to us once they have the insurance and removed the suspension.</h2>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/placas/WouldLikeRemoveSuspension ">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Aceptar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv/placas/WouldLikeRemoveSuspension ">
                        <Button variant="contained" color="success" size="large" sx={{ width: "50%" }}>Test</Button>
                    </Link>
                </Grid>
                <Outlet/>

            </Grid>
        </Box>
    );
};

export default AppBoardScreen;