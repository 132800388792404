import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Outlet, Link } from "react-router-dom";

import seguroDeAuto from'./img/start/seguro-de-auto.jpg';
import DMV from'./img/start/dmv.jpg';
import seguroParaMexico from'./img/start/seguro-para-mexico.jpg';

const Home = () => {
    return (
        <Box id="AppBoardStart" className="AppBoardScreen AppBoardStart StartAppBoardScreen" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12}>
                    <h1 className="mainHeading">Main Menu</h1>
                </Grid>
                <Grid item xs={12}>
                    <p>Cotiza.AseguranzaDeAuto.com</p>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/">
                        <img src={seguroDeAuto} alt="Seguro De Auto" />
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/dmv">
                        <img src={DMV} alt="DMV" />
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/">
                        <img src={seguroParaMexico} alt="Seguro Para Mexico" />
                    </Link>
                </Grid>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default Home;