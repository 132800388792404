import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import { useLocalStorage } from "../components/useLocalStorage";
import { useNavigate, Outlet, Link } from "react-router-dom";
import { useState } from "react";

const AppBoardScreen = () => {
    const [DoYouKnowWhichPartProcessIncompleteHidden, setDoYouKnowWhichPartProcessIncompleteHidden] = useLocalStorage("RegistrationRequireInfoHidden", "");
    const navigate = useNavigate();
    const [buttonStatus, setButtonStatus] = useState(false);  
    const inputChangeHandler = () => {
        setTimeout(() => {
            if (DoYouKnowWhichPartProcessIncompleteHidden.length > 0 ) {
                setButtonStatus(true);
            } else {
                setButtonStatus(false);
            }    
        }, 100)
    };
      
    inputChangeHandler();
          
    return (
        <Box id="AppBoardScreen2" className="AppBoardScreen AppBoardScreen2" sx={{ width: 800, m: "auto",  backgroundColor: '#fefefe', p: 6.25 }}>
             <input type="hidden" value={DoYouKnowWhichPartProcessIncompleteHidden} />
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                    <Link to="/dmv/placas/ItLooksLikeAlreadySomeOtherProcesStarted">
                        <Button variant="outlined" size="large">Regresar</Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h2 className="subHeading">¿Cónoce en que parte del proceso se quedó incompleto?</h2>
                </Grid>
                <Grid item xs={12}>
                        <Button disabled={!buttonStatus}  onClick={ () => { setDoYouKnowWhichPartProcessIncompleteHidden("Yes"); setTimeout(() => { navigate("/dmv/placas/InOrderContinueProcessFinishProcesSpending")}, 100) }} variant="contained" color="success" size="large" sx={{ width: "50%" }}>Si</Button>
                </Grid>
                <Grid item xs={12}>
         
                        <Button disabled={!buttonStatus}  onClick={ () => { setDoYouKnowWhichPartProcessIncompleteHidden("No"); setTimeout(() => { navigate("/dmv/placas/OrderCarryOutHistoryAdditionalPreventinga")}, 100) }} variant="contained" color="error" size="large" sx={{ width: "50%" }}>No</Button>
  
                </Grid>
                <Outlet/>
            </Grid>
        </Box>
    );
};

export default AppBoardScreen;